/**
 * Coordinates in two-dimensional space
 *
 * @category Breadboard
 */

// TODO: split XYPoint in two different type of points: one for board relative cell coordinates and one for window size coordinates
export interface XYPoint {
  x: number;
  y: number;
}
export interface XYRange {
  src: XYPoint;
  dst: XYPoint;
}
export type XYRangeOrPoint = XYRange | XYPoint;

/**
 *
 *
 * @category Breadboard
 */
export enum Direction {
  Up,
  Right,
  Down,
  Left,
}

export type ReplaceTypes<ObjType extends object, FromType, ToType> = {
  [KeyType in keyof ObjType]: ObjType[KeyType] extends object
  ? ReplaceTypes<ObjType[KeyType], FromType, ToType> // Recurse
  : ObjType[KeyType] extends FromType // Not recursing, need to change?
  ? ToType | ObjType[KeyType] // Yes, change it
  : ObjType[KeyType]; // No, keep original
};

export type ReplaceTypesOptional<ObjType extends object, FromType, ToType> = {
  [KeyType in keyof ObjType]: ObjType[KeyType] extends object
  ? ReplaceTypes<ObjType[KeyType], FromType, ToType> // Recurse
  : ObjType[KeyType] extends FromType // Not recursing, need to change?
  ? ToType | ObjType[KeyType] // Yes, change it
  : ObjType[KeyType]; // No, keep original
};

export type DefaultsOf<T extends object> = ReplaceTypesOptional<
  T,
  unknown,
  undefined
>;

export type DeepPartial<T> = T extends object
  ? {
    [P in keyof T]?: DeepPartial<T[P]>;
  }
  : T;
