import type SVG from 'svg.js';
import { type Cell } from '../../core/Grid';

import {
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';
import { normalizeWeight } from '~/js/utils/breadboard/core/Current/helpers';

export enum LEDPlateColor {
  Red = 'R',
  Green = 'G',
  Blue = 'B',
}

const LEDColors = {
  [LEDPlateColor.Red]: '#ff0000',
  [LEDPlateColor.Green]: '#00ff00',
  [LEDPlateColor.Blue]: '#0000ff',
};

type LEDPlateProps = PlateProps & {
  color: 'R' | 'G' | 'B';
};

type LEDPlateState = PlateState & {
  field: number;
};

/**
 * LED plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class LEDPlate extends LinearPlate<LEDPlateProps, LEDPlateState> {
  private _led: SVG.Circle;

  static get Alias() {
    return PLATE_ALIASES.LED;
  }

  protected get __inheritProps__() {
    return {
      color: 'R',
    } as const;
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  private _svgout: SVG.Text;

  public get variant() {
    return this.props.color;
  }

  /**
   * Установить состояние светодиода
   *
   * @param {object} state новое состояние светодиода
   */
  public setState(
    state: Partial<SerializedPlateState<LEDPlateState>>,
    suppress_events: boolean = false,
  ) {
    state.field = Number(state.field);

    super.setState(state, suppress_events);

    this._toggleLed();

    if (this.options.verbose) {
      this._redrawOutput(state.field);
    }
  }

  protected __setProps__(props: LEDPlateProps) {
    let color = props.color;

    if (!'RGB'.includes(color)) {
      console.error('Colour of LED must be one of R, G, B. Falling back to R');

      color = 'R';
    }

    super.__setProps__({ ...props, color });
  }

  /**
   * Нарисовать диод
   *
   * @param {Cell}   position     положение светодиода
   * @param {string}  orientation ориентация светодиода
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();
    this._drawLabel();
    this._drawLed();

    this._toggleLed(true);

    if (this.options.verbose) {
      this._redrawOutput(this.state.field);
    }

    // this._group.text(`Diode ${this._params.colour}`).font({size: 20});
  }

  private _redrawOutput(output_value: number) {
    if (!this._svgout) {
      const cell = this.__grid.getCell(0, 0);
      this._svgout = this._group
        .text('0')
        .center(cell.center_rel.x, cell.center_rel.y)
        .addClass('bb-plate-fill')
        .style({ size: 18 });
    }

    this._svgout.text(output_value ? '1' : '0');
  }

  /**
   * Draws LEDs on the plate surface
   *
   * @param {number} qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    this._group
      .polyline([
        [0, 0],
        [line_len, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .fill('none')
      .move(rect1.cx(), rect2.cy());

    const trng = this._group
      .polyline([
        [0, 0],
        [0, (qs * 3) / 2],
        [qs, (qs * 3) / 4],
        [0, 0],
      ])
      .addClass('bb-plate-stroke')
      .addClass('bb-plate-fill')
      .stroke({ width: 1 })
      .cx(rect1.cx() + line_len / 2)
      .cy(rect1.cy());

    const ptrpath = [
      [0, 0],
      [qs / 2, -qs / 2],
      [qs / 2 - 7, -qs / 2 + 4],
      [qs / 2 - 4, -qs / 2 + 7],
      [qs / 2, -qs / 2],
    ];

    const ptr1 = this._group
      .polyline(ptrpath)
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 });
    const ptr2 = this._group
      .polyline(ptrpath)
      .stroke({ width: 1 })
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .fill('#000');

    ptr1.move(trng.x() + trng.width() / 2, trng.y() - trng.height() / 4);
    ptr2.move(
      trng.x() + trng.width() / 2 + 5,
      trng.y() - trng.height() / 4 + 5,
    );
  }

  /**
   * Draws a label for the LED
   *
   * @param size label font size
   */
  private _drawLabel(size = Plate.LabelFontSizePreferred) {
    this._group
      .text(this.props.color)
      .addClass('bb-plate-caption')
      .font({ size })
      .cx(this._container.width() - size)
      .cy(this._container.height() - size)
      .stroke({ width: 0.5 });
  }

  private _toggleLed(force = false) {
    if (!this._is_drawn && !force) {
      return;
    }

    if (this.state.field) {
      this._led.opacity(normalizeWeight(this.state.field));
    } else {
      this._led.opacity(0);
    }
  }

  private _drawLed(size = Plate.QuadSizePreferred) {
    const filterId = `softGlow-${this.id}`;

    this._led = this._group
      .circle(size)
      .fill({
        color: LEDColors[this.props.color],
      })
      .cx(this._container.width() / 2)
      .cy(this._container.height() / 2)
      .opacity(0)
      .attr('filter', `url(#${filterId})`);

    const filter = `
        <filter id="${filterId}" width="300%" height="300%" x="-100%" y="-100%">
          <feGaussianBlur in="thicken" stdDeviation="5" result="blurred" />
        </filter>
      `;

    const defsNode = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'defs',
    );
    defsNode.innerHTML = filter;
    this._container.node.appendChild(defsNode);
  }
}
