import type SVG from 'svg.js';
import { type XYRange } from '~/js/utils/breadboard/types';
import { Cell } from '~/js/utils/breadboard/core/Grid';
import { type DeleteWireCallback } from '~/js/utils/breadboard/core/Wire/types';
import {
  type Connection,
  type ConnItem,
  type RemountParams,
  type UpdateSelectionParams,
} from '~/js/utils/breadboard/components/layers/WireLayer/types';
import {
  connectionItemToPosition,
  getJunctionPosition,
  isSameConnItem,
} from '~/js/utils/breadboard/components/layers/WireLayer/helpers';
import { type CurrentPath } from '~/js/utils/breadboard/core/Current';

/**
 * An SVG graphical representation of Wire unit
 *
 * Keeps track of state and properties of the Wire,
 * implements basic operations and UI interaction logic.
 *
 * @category Breadboard
 * @subcategory Wires
 */
export abstract class Wire {
  protected _container: SVG.Container;

  protected _id: number;
  protected _color: string;

  protected _conn: Connection;
  protected _pos: XYRange;
  protected _is_dragging: boolean;
  public ___touched: boolean;

  protected readonly _callbacks: {
    debugenter: (wire?: Wire) => void;
    debugleave: (wire?: Wire) => void;
    mouseenter: CallableFunction;
    mouseleave: CallableFunction;
    delete: DeleteWireCallback;
  };

  protected _editable: boolean;

  /**
   * Creates the Wire
   *
   * @param container an SVG container to generate elements inside
   * @param id optional ID, the random number is used if omitted
   */
  protected constructor(container: SVG.Container, id?: number) {
    this._container = container.nested();

    this._callbacks = {
      debugenter: () => {},
      debugleave: () => {},
      mouseenter: () => {},
      mouseleave: () => {},
      delete: () => {},
    };

    this._id = id != null ? id : Math.floor(Math.random() * 10 ** 6);
    this._editable = true;
  }

  /**
   * Unique identifier of the Wire
   */
  public get id() {
    return this._id;
  }

  /**
   * Current color assigned to the Wire
   */
  public get color() {
    return this._color;
  }

  /**
   * Connection data of the Wire
   *
   * To get the current position of the points, use {@link pos}
   */
  public get conn() {
    return this._conn;
  }

  /**
   * Current position data of the graphical wire
   * (e.g. the line representing the Wire)
   */
  public get pos() {
    return this._pos;
  }

  /**
   * Draws the Wire from the source point
   *
   * To meet the UI logic requirements, when Wire might be rendered still unmounted,
   * the function takes only the source position.
   * The destination is defined in {@link mount}.
   *
   * The function keeps the position data but not the connection data,
   * so the Wire should not be exported until mounted.
   *
   * Override the function to initialize the drawing.
   *
   * If your UI doesn't need to show the drawing process
   * of the Wire and its final destination is defined,
   * simply call the {@link mount} method after initial draw.
   *
   * @param src source (and destination yet) of the Wire being drawn
   */
  public draw(src: ConnItem) {
    const pos_src =
      src instanceof Cell ? src.center : getJunctionPosition(src.cells);
    this._pos = { src: pos_src, dst: pos_src };
  }

  /**
   * Changes the graphical position of the Wire
   *
   * For UI requirements, this function is helpful when the wire is being dragged
   * or to make movements when the wire is drawn but not yet mounted.
   *
   * Override the function to sync the wire shape with its current position
   * and, if needed, to set its dragging properties (e.g. styles and animations)
   *
   * @param pos_src source position of the graphical wire
   * @param pos_dst destination position of the graphical wire
   * @param is_drag whether the wire is being dragged
   */
  public update({ pos_src, pos_dst, is_drag = false }: UpdateSelectionParams) {
    if (pos_src) {
      this._pos.src = { ...pos_src };
    }

    if (pos_dst) {
      this._pos.dst = { ...pos_dst };
    }

    this._is_dragging = is_drag;
  }

  /**
   * Mounts the Wire on its final connection points
   *
   * The function updates the connection of Wire if the orientation is known.
   *
   * Override the function to apply the final state of the Wire
   * and, if needed, to set its mount properties.
   *
   * @param src source connection point of the Wire
   * @param dst destination connection point the Wire
   */
  public mount({ src, dst }: Connection) {
    this._conn = { src, dst };

    let pos_src = connectionItemToPosition(src);
    let pos_dst = dst && connectionItemToPosition(dst);

    // ensure the wire's graphical position is correct
    this.update({ pos_src, pos_dst, is_drag: false });
  }

  public setEditableVisual(editable = true) {
    this._editable = editable;
  }

  /**
   * Changes the Wire' source and destination based on its orientation
   *
   * Generally, when the remount is required, it's unknown whether
   * the wire ts connected _to_ or _from_ the Junction.
   * To keep the right orientation, use this method instead of {@link mount}.
   *
   * @param cell
   * @param junction
   */
  public remountByConnType({ cell, junction }: RemountParams) {
    if (this._conn.src instanceof Cell) {
      this.mount({ src: cell, dst: junction });
    } else {
      this.mount({ src: junction, dst: cell });
    }
  }

  /**
   * Changes the Wire' source and destination based on its current connection
   *
   * Generally, when the remount is required, it's unknown how is original wire
   * is oriented.
   * To keep the right orientation, use this method instead of {@link mount}.
   *
   * @param item1
   * @param item2
   */
  public remountByConn(item1: ConnItem, item2: ConnItem) {
    if (
      isSameConnItem(this._conn.src, item2) ||
      isSameConnItem(this._conn.dst, item1)
    ) {
      this.mount({ src: item2, dst: item1 });
    } else {
      this.mount({ src: item1, dst: item2 });
    }
  }

  public abstract getPath(): string | CurrentPath | null;

  public abstract dispose(): void;

  public onDelete(cb?: DeleteWireCallback) {
    this._callbacks.delete = cb || (() => undefined);
  }

  public getConnectedCell() {
    if (this._conn.src instanceof Cell) {
      return this._conn.src;
    } else if (this._conn.dst instanceof Cell) {
      return this._conn.dst;
    }

    throw new Error('Unhandled error: Wire is not connected to any cell');
  }

  /**
   * Attaches a mouse enter event
   *
   * @param cb callback function to be called when the cursor entered the wire
   */
  public onMouseEnter(cb: CallableFunction): void {
    if (!cb) {
      cb = () => {};
    }

    this._callbacks.mouseenter = cb;
  }

  /**
   * Attaches a mouse leave event
   *
   * @param cb callback function to be called when the cursor left the wire
   */
  public onMouseLeave(cb: CallableFunction): void {
    if (!cb) {
      cb = () => {};
    }

    this._callbacks.mouseleave = cb;
  }

  public onDebugEnter(cb: (wire: Wire) => void): void {
    if (!cb) {
      cb = () => {};
    }

    this._callbacks.debugenter = cb;
  }

  public onDebugLeave(cb: (wire: Wire) => void): void {
    if (!cb) {
      cb = () => {};
    }

    this._callbacks.debugleave = cb;
  }
}
