import { type Cell } from '../../core/Grid';

import {
  Orientation,
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
  type SerializedPlateWithId,
} from '../../core/Plate';
import { type XYPoint } from '../../types';
import { PLATE_ALIASES } from './constants';

/**
 * The jumper plate
 *
 * Jumper plate do not hove any graphical implementation,
 * it's used to provide plate-related information to
 * non-plate-like Wire units, which are presented by the {@link Wire} objects.
 *
 * To keep the convenience, all unit types should have a mapping to Plates,
 * regardless of their real purpose.
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class JumperPlate extends Plate {
  static get Alias() {
    return PLATE_ALIASES.JUMPER;
  }

  private _cells: Cell[];

  public get cells() {
    return this._cells;
  }

  /**
   * @inheritdoc
   */
  protected get __length__() {
    return 0;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  protected __draw__(cell: Cell, orientation: string): void {
    throw new Error('Method not implemented.');
  }

  public setState(
    state: Partial<SerializedPlateState<PlateState>>,
    suppress_events?: boolean,
  ): void {
    if (!state.position?.cells) {
      return;
    }

    this._cells =
      state.position?.cells.map((point) =>
        this.__grid.getCell(point.x, point.y),
      ) || [];
  }

  serialize(): SerializedPlateWithId<PlateProps, PlateState> {
    const base = super.serialize();
    return {
      ...base,
      state: {
        ...base.state,
        position: {
          ...base.state.position,
          cells: this._cells.map((cell) => cell.idx),
        },
      },
    };
  }

  public convertCellsToPosition(cells: XYPoint[]) {
    return { cell: { x: 0, y: 0 }, orientation: Orientation.West };
  }

  public draw(cell: Cell, orientation: Orientation, animate?: boolean): void {}
}
