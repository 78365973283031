import { Popup } from '../../../core/Popup';
import { Current } from '../../../core/Current';
import { invertHexRgb } from '../../../helpers/common';
import { optimizeUnit } from '../../../helpers/units';
import { type CurrentPopupContent } from './types';
import { getConnItemRepresentation } from '~/js/utils/breadboard/core/Wire/helpers';

/**
 * Popup that displays when the cursor hovers a {@link Current}
 */
export class CurrentPopup extends Popup<CurrentPopupContent> {
  /**
   * Updates the content of current popup
   *
   * @param content details for the popup
   * @param verbose show debug info
   */
  public updateContent(content: CurrentPopupContent): void {
    this._container.style.visibility = content.current.weight
      ? 'visible'
      : 'hidden';

    const rgb = Current.pickColorFromRange(content.current.weight);

    this._container.style.backgroundColor = rgb;
    this._container.style.color = invertHexRgb(rgb, true);
    this._container.innerHTML = `<b>Current</b>: ${optimizeUnit(
      content.current.weight_thread,
      'A',
    )}`;

    // if (content.voltage) {
    const voltage = content.voltage;

    if (voltage != null) {
      this._container.innerHTML += `<br/><b>Voltage</b>: ${optimizeUnit(
        voltage,
        'V',
      )}`;
    }

    if (!content.verbose) {
      return;
    }

    let debug_info = '';

    debug_info += `<i>#${content.current.id}</i><br/>`;

    debug_info += `<b>Voltage</b>: ${content.voltage}<br/>`;

    if (content.wire && content.unit && content.pin_idx != null) {
      debug_info += `<b>Net/Unit ID</b>: ${content.net_id}`;

      // Get Wire Src/Dst
      const conn_src = content.wire.conn.src;
      const conn_dst = content.wire.conn.dst;

      const src = getConnItemRepresentation(conn_src);
      const dst = conn_dst
        ? getConnItemRepresentation(conn_dst)
        : '<i>None</i>';

      debug_info += `<br/><b>Wire Src</b>: ${src}<br/><b>Wire Dst:</b> ${dst}`;

      // Get Pin IDX
      debug_info += `<br/><b>Pin IDX</b>: [${content.pin_idx}]`;

      // Get Unit Currents
      const currents = content.unit.currents
        .map((weight, weight_idx) =>
          weight_idx === content.pin_idx ? `<b><u>${weight}</u></b>` : weight,
        )
        .join(', ');

      debug_info += `<br/><b>Unit Currents</b>: [${currents}]`;
      debug_info += '<br/><b>Voltages</b>: UNKNOWN';
    } else {
      const thr = content.thread;

      const src =
        typeof thr?.src === 'number'
          ? `<junc ${thr.src}>`
          : `[${thr?.src?.x}, ${thr?.src.y}]`;
      const dst =
        typeof thr?.dst === 'number'
          ? `<junc ${thr.dst}>`
          : `[${thr?.dst?.x}, ${thr?.dst.y}]`;

      debug_info += `<b>Src</b>: ${src}`;
      debug_info += `<br/><b>Dst</b>: ${dst}`;
    }

    this._container.innerHTML += `<hr/><div class='bb-popup-debug'>${debug_info}</div>`;
  }

  protected __draw__(content: CurrentPopupContent): void {
    this.updateContent(content);
  }
}
