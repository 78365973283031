import { enumerate } from '../../helpers/common';
import { type XYPoint, type XYRange, type XYRangeOrPoint } from '../../types';
import { type Cell } from '../Grid';
import {
  CellRole,
  type Domain,
  DomainSlice,
  type DomainTable,
  type EmbeddedPlate,
  type LineTable,
  type PinState,
} from '../Layout';

export const ARD_PIN_VAR_PREFIX = 'A';

export function* sliceXYRange(
  range: XYRange,
  slice?: DomainSlice,
): Generator<XYRange> {
  const { src, dst } = range;

  let dyn_min, dyn_max;

  // if (range.from.x === range.to.x && range.from.y === range.to.y) return { from, to };
  if (slice == null) {
    return { src, dst };
  }

  if (slice === DomainSlice.Arbitrary) {
    const [x_min, x_max] = [src.x, dst.x];
    const [y_min, y_max] = [src.y, dst.y];

    for (let x = x_min; x <= x_max; x++) {
      for (let y = y_min; y <= y_max; y++) {
        yield { src: { x, y }, dst: { x, y } };
      }
    }

    return;
  }

  if (slice === DomainSlice.Horizontal) {
    [dyn_min, dyn_max] = [src.y, dst.y];
  } else {
    [dyn_min, dyn_max] = [src.x, dst.x];
  }

  [dyn_min, dyn_max] = minmax(dyn_min, dyn_max);

  for (let dyn = dyn_min; dyn <= dyn_max; dyn++) {
    let _src, _dst;

    if (slice === DomainSlice.Horizontal) {
      _src = { x: src.x, y: dyn };
      _dst = { x: dst.x, y: dyn };
    } else {
      _src = { x: dyn, y: src.y };
      _dst = { x: dyn, y: dst.y };
    }

    yield { src: _src, dst: _dst };
  }
}

/**
 * Generates sequence of points lying on the same axis (x or y)
 * Order of numbers is defined by the order of range numbers
 *
 * You can substitute the original fixed axis value,
 * which stays the same for each object
 * to customize the sequence.
 *
 * If p_src.x === p_dst.y, the fixed axis is X
 * If p_src.y === p_dst.y, the fixed axis is Y
 *
 * @param range     first and last points of the sequence (included)
 * @param fix_subst fixed axis value substitutor
 */
export function* pointseq(range: XYRange, fix_subst?: number) {
  if (isFixedXY(range)) {
    // X is fixed
    for (const y of numseq(range.src.y, range.dst.y)) {
      yield { x: fix_subst || range.dst.x, y };
    }
  } else {
    // Y is fixed
    for (const x of numseq(range.src.x, range.dst.x)) {
      yield { x, y: fix_subst || range.dst.y };
    }
  }
}

export function* countseq<V>(count: number, value: V) {
  for (let i = 0; i < count; i++) {
    yield value;
  }
}

/**
 * Generates sequence of numbers in given range
 * Order of numbers is descending if `from` less than `to`
 *
 * @param from first number of the sequence (included)
 * @param to   last number of the sequence (included)
 */
export function* numseq(from: number, to: number) {
  // [from, to] = [Math.min(from, to), Math.max(from, to)];
  if (from > to) {
    for (let i = from; i >= to; i--) {
      yield i;
    }
  } else {
    for (let i = from; i <= to; i++) {
      yield i;
    }
  }
}

export function rangeOrPointToRange(point_or_range: XYRangeOrPoint): XYRange {
  if (point_or_range.hasOwnProperty('x')) {
    return {
      src: point_or_range as XYPoint,
      dst: point_or_range as XYPoint,
    };
  }

  return point_or_range as XYRange;
}

export function minmaxfix(r: XYRange): [number, number] {
  if (isFixedXY(r)) {
    return [Math.min(r.src.x, r.dst.x), Math.max(r.src.x, r.dst.x)];
  } else {
    return [Math.min(r.src.y, r.dst.y), Math.max(r.src.y, r.dst.y)];
  }
}

export function minmaxdyn(r: XYRange): [number, number] {
  if (!isFixedXY(r)) {
    return [Math.min(r.src.x, r.dst.x), Math.max(r.src.x, r.dst.x)];
  } else {
    return [Math.min(r.src.y, r.dst.y), Math.max(r.src.y, r.dst.y)];
  }
}

export function fixXY(range: XYRange): number {
  if (isFixedXY(range)) {
    return range.src.x;
  } else {
    return range.src.y;
  }
}

export function hasSameAxis(cells: Cell[]): [boolean, boolean] {
  return cells.reduce(
    ([sameX, sameY], cell, i, all) =>
      i === 0
        ? [true, true]
        : [
          sameX && cell.idx.x === all[i - 1].idx.x,
          sameY && cell.idx.y === all[i - 1].idx.y,
        ],
    [true, true],
  );
}

export function isFixedXY(range: XYRange) {
  if (range.src.x === range.dst.x) {
    return true;
  } else if (range.src.y === range.dst.y) {
    return false;
  }

  throw Error('Points are placed on different axes');
}

export function minmax(v1: number, v2: number): [number, number] {
  return [Math.min(v1, v2), Math.max(v1, v2)];
}

/**
 * Returns min and max cells compared by its non-fixed axis
 *
 * @param c1 first cell to compare
 * @param c2 second cell to compare
 */
export function minmaxfixCell(c1: Cell, c2: Cell): [Cell, Cell] {
  const isFixedX = c1.idx.x === c2.idx.x;
  const isFixedY = c1.idx.y === c2.idx.y;

  if (isFixedX) {
    return c1.idx.y < c2.idx.y ? [c1, c2] : [c2, c1];
  }

  if (isFixedY) {
    return c1.idx.x < c2.idx.x ? [c1, c2] : [c2, c1];
  }

  throw Error('Points are placed on different axes');
}

export function extractAnalogPoints(
  lines: LineTable,
  pin_state: PinState,
): XYPoint[] {
  // prettier-ignore
  return Object.values(lines).filter(
    // take analog lines only
    line => (
      line.role === CellRole.Analog &&
      line.pin_state_initial === pin_state
    ),
  ).map(
    // take only points from them
    line => line.points,
  ).reduce(
    // join all points to single array
    (prev, next) => prev.concat(next),
  );
}

export function scanDomains(domains: DomainTable): LineTable {
  const ds = Object.entries(domains);

  let lines: LineTable = {};

  let analog_num = 0;

  // scan non-analog domains
  for (const [d_id, d] of ds) {
    if (d.props?.role === CellRole.Analog) {
      const analog = scanDomainAnalog(d, d_id, analog_num);
      analog_num += Object.keys(analog).length;

      lines = { ...lines, ...analog };
    } else {
      lines = { ...lines, ...scanDomain(d, d_id) };
    }
  }

  return lines;
}

function scanDomain(d: Domain, d_id: string): LineTable {
  let line_id;

  const field = rangeOrPointToRange(d.field);
  // generate scanning of the range
  const points = [...pointseq(field)];
  // add scanning of the virtual range if exists

  d.virtual && points.push(...pointseq(d.virtual));

  // find line of plus and minus
  if (d?.props?.role === CellRole.Plus) {
    points.unshift({ x: -1, y: d.field.src.y });
    line_id = `pls.${d_id}`;
  } else if (d?.props?.role === CellRole.Minus) {
    points.unshift({ x: -1, y: d.field.src.y });
    line_id = `min.${d_id}`;
  } else if (points.length === 1) {
    const point = points[0];
    line_id = `sng.${point.x}.${point.y}`;
  } else {
    line_id = `mlt.${d_id}`;
  }

  // QUESTION: haven't we already added this point?
  if (d.props?.role && d.props.role in [CellRole.Minus, CellRole.Plus]) {
    points.unshift({ x: -1, y: field.src.y });
  }

  return { [line_id]: { points, role: d?.props?.role, field: d.field } };
}

function scanDomainAnalog(
  d: Domain,
  d_id: string,
  total_analog_num: number,
): LineTable {
  const lines: LineTable = {};

  let pin_id = 0;

  const field = rangeOrPointToRange(d.field);
  // generate scanning of the range
  const points = [...pointseq(field)];

  for (const [p_id, point] of enumerate(points)) {
    const line_id = `ana.${d_id}.${p_id}`;

    lines[line_id] = {
      points: [point],
      role: d?.props?.role,
      pin_state_initial: d?.props?.pin_state_initial,
      field: rangeOrPointToRange(point),
      embedded_plate:
        d &&
        d.props?.pin_state_initial &&
        d.minus &&
        getArduinoPinPlate(
          point,
          d.minus(p_id, point),
          d.props.pin_state_initial,
          total_analog_num + pin_id,
        ),
    };

    pin_id += 1;
  }

  return lines;
}

export function getVoltageSourcePlate(
  coords_minus: XYPoint,
  coords_vcc: XYPoint,
): EmbeddedPlate<any> {
  return {
    type: 'Vss',
    id: String(-1000),
    position: [coords_minus, coords_vcc],
    props: {
      volt: 5,
    },
  };
}

export function getArduinoPinPlate(
  arduino_node: XYPoint,
  minus_node: XYPoint,
  pin_state_initial: PinState,
  ard_plate_ser_num: number,
): EmbeddedPlate<any> {
  return {
    type: 'ard_pin',
    id: String(-1001 - ard_plate_ser_num),
    position: [
      { x: arduino_node.x, y: arduino_node.y },
      { x: minus_node.x, y: minus_node.y },
    ],
    props: {
      volt: 5,
      analogue_max_value: 100,
      var_name: `${ARD_PIN_VAR_PREFIX}${ard_plate_ser_num}`,
    },
    pin_state_initial,
  };
}
