import { PhotoresistorPlate } from '~/js/utils/breadboard/components/plates/PhotoresistorPlate';
import {
  Plate,
  type PlateState,
} from '../../core/Plate';
import { PLATE_ALIASES } from './constants';

interface PhototransistorPlateState extends PlateState {
  field: number;
}

/**
 * Photoresistor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class PhototransistorPlate extends PhotoresistorPlate {
  static get Alias() {
    return PLATE_ALIASES.PHOTOTRANSISTOR;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  protected _drawPicture(qs: number = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    const fs = Plate.LabelFontSizePreferred - 2;

    const line_height = this._container.height() / 4;
    const x_center = (cell1.center_rel.x + cell2.center_rel.x) / 2;

    const line_left = this._group.path([
      ['M', cell1.center_rel.x, cell2.center_rel.y],
      ['L', cell1.center_rel.x, line_height],
      ['H', x_center - 12],
      ['L', x_center, line_height + 12],
    ]);

    const line_right = this._group.path([
      ['M', cell2.center_rel.x, cell2.center_rel.y],
      ['L', cell2.center_rel.x, line_height],
      ['H', x_center + 12],
      ['L', x_center, line_height + 12],
    ]);

    const line_middle = this._group.path([
      ['M', x_center, line_height + 20],
      ['V', 50],
    ]);

    line_left.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');
    line_right.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');
    line_middle.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');

    this._drawPhotoElement(rect1, line_len, qs);

    const ptrpath = [
      [0, 0],
      [qs / 2, -qs / 2],
      [qs / 2 - 7, -qs / 2 + 4],
      [qs / 2 - 4, -qs / 2 + 7],
      [qs / 2, -qs / 2],
    ];

    const ptr1 = this._group
      .polyline(ptrpath)
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 });

    ptr1.move(x_center + 2.5, line_height);

    this._group
      .text(this._getCaption())
      .addClass('bb-plate-caption')
      .font({ size: fs, anchor: 'middle' })
      .x(this._container.width() / 2)
      .y(this._container.height() - fs * 2);
  }

  protected _getCaption(): string {
    return 'PH. TRANSISTOR';
  }
}
