import { TransistorNpnPlate } from '~/js/utils/breadboard/components/plates/TransistorNpnPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Transistor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class TransistorPnpPlate extends TransistorNpnPlate {
  static get Alias() {
    return PLATE_ALIASES.TRANSISTOR_PNP;
  }

  protected _getLabel() {
    return 'PNP';
  }
}
