import { clone } from 'lodash';
import type SVG from 'svg.js';
import { isEditingAllowed } from '~/js/utils/breadboard/Breadboard.helpers';
import {
  connToString,
  findLoops,
  getUniqueWireCells,
  isSameConnection,
  isSameConnectionCell,
  wiresToLinkedGroups,
} from '~/js/utils/breadboard/components/layers/WireLayer/helpers';
import { Plate } from '~/js/utils/breadboard/core/Plate';
import { type Wire } from '~/js/utils/breadboard/core/Wire/Wire';
import { getConnItemRepresentation } from '~/js/utils/breadboard/core/Wire/helpers';
import { type BreadboardMode } from '../../../Breadboard.types';
import { type Cell, type Grid } from '../../../core/Grid';
import { Layer, type LayerOptions } from '../../../core/Layer';
import { getCursorPoint } from '../../../core/Layer/helpers';
import { SimpleWire } from '../../../core/Wire/SimpleWire';
import {
  type CreateWireParams,
  type Junction,
  type Net,
  type WireChangeCallback,
  type PointOnMouseOverCallback,
  type PointOnMouseOutCallback,
  type WireSerialized,
} from './types';
import { XYPoint } from '../../../types';

/**
 * Wire creation and displacement layer
 *
 * Implements the rules of displacement
 */
export class WireLayer extends Layer {
  /** current wire */
  private _wire?: Wire;
  /** wire database */
  private _network: Record<number, Net>;
  /** connection points */
  private _points: SVG.Element[][];
  /** visible connection points */
  private _vpoints: SVG.Element[][];
  /** highlighted connection points */
  private _hpoints: SVG.Element[][];

  /* wire group */
  private _wiregroup: SVG.G;
  /* visual point group */
  private _vpointgroup: SVG.G;
  /* logical point group */
  private _pointgroup: SVG.G;
  /* debug group */
  private _debuggroup: SVG.G;

  private readonly _callbacks: {
    change: WireChangeCallback,
    pointMouseOver: PointOnMouseOverCallback,
    pointMouseOut: PointOnMouseOutCallback
  };

  private _cell_dst_tmp?: Cell;
  private _debug_text?: SVG.Text;

  /** CSS class of the layer */
  static get Class() {
    return 'bb-layer-wire';
  }

  constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.addClass(WireLayer.Class);

    this._callbacks = {
      change: () => {},
      pointMouseOver: (cell_idx: XYPoint) => {},
      pointMouseOut: (cell_idx: XYPoint) => {}
    };

    this._network = {};
    this._wire = undefined;

    this._handleMouseMove = this._handleMouseMove.bind(this);
    this._handlePointMouseDown = this._handlePointMouseDown.bind(this);
    this._handlePointMouseUp = this._handlePointMouseUp.bind(this);
  }

  public __compose__(): void {
    this._initGroups();
    this._drawPoints();
    this._attachEventsEditable();
  }

  public recompose(options: LayerOptions): void {
    super.recompose(options);

    const wires = this.getWires();

    this._clearGroups();
    this.compose();

    this.setWires(wires);
  }

  public getWires(): WireSerialized[] {
    return Object.entries(this._network).map(([id, net]) => {
      return { id: Number(id), cells: getUniqueWireCells(Object.values(net)) };
    });
  }

  public getNetwork() {
    return clone(this._network);
  }

  public setWires(wires_serialized: WireSerialized[]) {
    if (this.__grid.isRestrictWires) {
      return;
    }

    this.deleteAllWires(true);

    this._buildNetwork(wires_serialized);

    this._callbacks.change();
  }

  public createWire({ id, src, dst }: CreateWireParams) {
    const isEditable = isEditingAllowed(this.__mode);

    const wire = new SimpleWire(this._wiregroup, id);
    // const wire = new PhysicalWire(this._wiregroup, id);
    wire.draw(src);
    wire.mount({ src, dst });
    wire.setEditableVisual(isEditable);

    wire.onDelete((id) => {
      this.deleteWire(id);
    });

    this.attachWireEvents(wire);

    return wire;
  }

  public deleteWire(id: number, is_silent: boolean = false) {
    const nets = Object.entries(this._network);

    // find net by wire id
    const net_entry = nets.find(([net_id, net]) => net[id]);

    if (!net_entry) {
      throw new Error(`Net ${id} not found`);
    }

    const [net_id, net] = net_entry;

    // delete the wire
    const wire = net[id];
    wire.dispose();
    delete net[id];

    // delete the net
    if (Object.values(net).length === 0) {
      delete this._network[Number(net_id)];
    }

    if (!is_silent) {
      this._rebuildNetwork();
      this._callbacks.change();
    }
  }

  public deleteNet(id: number, is_silent: boolean = false) {
    const net = this._network[id];
    const wires = Object.values(net);

    for (const wire of wires) {
      wire.dispose();
    }

    delete this._network[id];

    if (!is_silent) {
      this._callbacks.change();
    }
  }

  public deleteAllWires(is_silent: boolean = false) {
    for (const id of Object.keys(this._network)) {
      const idNum = Number(id);

      this.deleteNet(idNum, true);
    }

    if (!is_silent) {
      this._callbacks.change();
    }
  }

  /**
   * Attaches a layer change event handler
   *
   * @param cb callback function to be called when the layer is changed
   */
  public onChange(cb?: WireChangeCallback) {
    this._callbacks.change = cb || (() => undefined);
  }

  /**
   * Attaches a pointMouseOver event handler
   *
   * @param cb callback function to be called when mouse is hovering on the point
   */
  public onPointMouseOver(cb?: PointOnMouseOverCallback) {
    this._callbacks.pointMouseOver = cb || ((cell_idx: XYPoint) => undefined);
  }

  /**
   * Attaches a pointMouseOut event handler
   *
   * @param cb callback function to be called when mouse is leaving the point
   */
  public onPointMouseOut(cb?: PointOnMouseOutCallback) {
    this._callbacks.pointMouseOut = cb || ((cell_idx: XYPoint) => undefined);
  }

  public attachWireEvents(wire: Wire) {
    wire.onDebugEnter((w) => {
      const src = getConnItemRepresentation(w.conn.src);
      const dst = w.conn.dst ? getConnItemRepresentation(w.conn.dst) : 'n/a';
      this._debug_text?.text(`#${w.id}: ${src} -> ${dst}`).fill('lightgreen');
    });

    wire.onDebugLeave(() => {
      this._debug_text?.fill('black');
    });
  }

  public setMode(mode: BreadboardMode) {
    super.setMode(mode);

    const wires = Object.values(this._network).reduce<Wire[]>(
      (acc, net) => [...acc, ...Object.values(net)],
      [],
    );

    for (const wire of wires) {
      wire.setEditableVisual(isEditingAllowed(mode));
    }
  }

  /**
   * Initializes internal SVG groups
   */
  private _initGroups() {
    this._clearGroups();

    // visual points
    this._vpointgroup = this._container.group();
    // visual wires
    this._wiregroup = this._container.group();
    // logical points
    this._pointgroup = this._container.group();

    this._debuggroup = this._container.group();

    if (this.__verbose) {
      this._debug_text = this._debuggroup
        .text('debug mode enabled')
        .move('96%', 10)
        .font({ family: Plate.CaptionFontFamily, anchor: 'end', weight: '700' })
        .fill('magenta');
    }
  }

  /**
   * Removes SVG groups created previously with {@link _initGroups}
   */
  private _clearGroups() {
    this._wiregroup?.remove();
    this._vpointgroup?.remove();
    this._pointgroup?.remove();
    this._debuggroup?.remove();
  }

  /** Draws cells (points of the regular grid) */
  private _drawPoints() {
    this._points = [];
    this._vpoints = [];
    this._hpoints = [];

    for (const col of this.__grid.cells) {
      for (const cell of col) {
        this._drawPoint(cell);
      }
    }
  }

  /**
   * Draws separate points of the {@link Grid}
   *
   * @param cell related {@link Cell} of the grid
   */
  private _drawPoint(cell: Cell) {
    if (this._points[cell.idx.x] == null) {
      this._points[cell.idx.x] = [];
      this._vpoints[cell.idx.x] = [];
      this._hpoints[cell.idx.x] = [];
    }

    this._hpoints[cell.idx.x][cell.idx.y] = this._vpointgroup
      .circle(10)
      .opacity(0)
      .center(cell.center.x, cell.center.y)
      .fill({ color: '#0f0' });

    this._vpoints[cell.idx.x][cell.idx.y] = this._vpointgroup
      .circle(10)
      .opacity(0)
      .center(cell.center.x, cell.center.y)
      .style({ cursor: 'pointer' })
      .fill({ color: '#f00' });

    this._points[cell.idx.x][cell.idx.y] = this._pointgroup
      .circle(30)
      .opacity(0)
      .center(cell.center.x, cell.center.y)
      .style({ cursor: 'pointer' });
  }

  private _attachEventsEditable() {
    for (const col of this.__grid.cells) {
      for (const cell of col) {
        this._attachPointEvents(cell);
      }
    }

    document.addEventListener('mouseup', this._handlePointMouseUp, false);
  }

  private _attachPointEvents(cell: Cell) {
    const point = this._points?.[cell.idx.x]?.[cell.idx.y];
    const vpoint = this._vpoints?.[cell.idx.x]?.[cell.idx.y];

    if (!this._points?.[cell.idx.x]?.[cell.idx.y]) {
      throw Error(
        `Tried to attach handler for cell (${cell.idx.x}, ${cell.idx.y}): No such point`,
      );
    }

    point.mouseover(() => {
      this._debug_text?.text(`x: ${cell.idx.x}, y: ${cell.idx.y}`);
      this._debug_text?.fill('red');

      vpoint.opacity(1);

      // show voltage
      this._callbacks.pointMouseOver(cell.idx);

      if (!this._wire) {
        return;
      }

      this._cell_dst_tmp = cell;
    });

    point.mouseout(() => {
      this._debug_text?.fill('black');

      vpoint.opacity(0);

      // hide voltage
      this._callbacks.pointMouseOut(cell.idx);

      if (!this._wire) {
        return;
      }

      this._cell_dst_tmp = undefined;
    });

    point.mousedown((evt: MouseEvent) => {
      this._handlePointMouseDown(evt, cell);
    });
  }

  private _handlePointMouseDown(evt: MouseEvent, cell: Cell) {
    if (this.__grid.isRestrictWires) {
      return;
    }

    if (!isEditingAllowed(this.__mode)) {
      return;
    }

    if (!(this._container.node instanceof SVGSVGElement)) {
      return;
    }

    if (evt.button !== 0) {
      return;
    }

    this._wire = this.createWire({ src: cell });

    // subscribe to mouse movements to move the plate while dragging
    document.body.addEventListener('mousemove', this._handleMouseMove, false);
  }

  private _handlePointMouseUp(evt: MouseEvent) {
    if (!this._wire) {
      return;
    }

    document.body.removeEventListener('mousemove', this._handleMouseMove);

    if (this._cell_dst_tmp) {
      const vpoint_idx = this._cell_dst_tmp?.idx;

      const vpoint = this._vpoints?.[vpoint_idx.x]?.[vpoint_idx.y];
      vpoint.opacity(0);
    }

    this._finishWire();
  }

  private _handleMouseMove(evt: MouseEvent) {
    if (!(this._container.node instanceof SVGSVGElement)) {
      return;
    }

    const cursor_point = getCursorPoint(
      this._container.node,
      evt.clientX,
      evt.clientY,
    );

    this._wire?.update({
      pos_dst: {
        x: cursor_point.x,
        y: cursor_point.y,
      },
      is_drag: true,
    });
  }

  /**
   * Removes the link to the wire being created
   *
   * @private
   */
  private _resetWire() {
    this._wire = undefined;
  }

  private _addWireToNetwork(wire: Wire) {
    const id = Math.floor(Math.random() * 10 ** 6);
    this._network[id] = {
      [wire.id]: wire,
    };

    this._rebuildNetwork();
    this._callbacks.change();
  }

  private _rebuildNetwork() {
    const wires = this._getAllWires();
    const groups = wiresToLinkedGroups(wires);

    const cell_groups = groups.map((group) => ({
      cells: getUniqueWireCells(group),
    }));

    this._buildNetwork(cell_groups);

    // console.group('groups', groups.length);
    //
    // for (const group of groups) {
    //   console.log(
    //     group.map(() => '%c■').join(' '),
    //     ...group.map((w) => `color: ${w.color};`),
    //   );
    // }
    //
    // console.groupEnd();
  }

  private _buildNetwork(groups: { id?: number; cells: Cell[] }[]) {
    const wires = this._getAllWires();

    for (const wire of wires) {
      wire.___touched = false;
    }

    const network_new: Record<string, Net> = {};

    for (const { id: id_, cells } of groups) {
      const id = id_ || Math.floor(Math.random() * 10 ** 6);

      let wires_new: Wire[];

      if (cells.length > 2) {
        const junction: Junction = { id, cells };

        wires_new = cells.map((cell) => {
          const w_near = wires.find((w) =>
            isSameConnection(w.conn, { src: cell, dst: junction }),
          );

          if (w_near) {
            w_near.remountByConnType({ cell, junction });
            w_near.___touched = true;
            return w_near;
          }

          const w_same = wires.find((w) => isSameConnectionCell(w.conn, cell));

          if (w_same) {
            w_same.remountByConnType({ cell, junction });
            w_same.___touched = true;
            return w_same;
          }

          const w_new = this.createWire({ src: cell, dst: junction });
          w_new.___touched = true;
          return w_new;
        });
      } else {
        const w_near = wires.find(
          (w) =>
            isSameConnectionCell(w.conn, cells[0]) ||
            isSameConnectionCell(w.conn, cells[1]),
        );

        if (w_near) {
          w_near.___touched = true;
          w_near.remountByConn(cells[0], cells[1]);
          wires_new = [w_near];
        } else {
          const wire = this.createWire({ src: cells[0], dst: cells[1] });
          wires_new = [wire];
        }
      }

      if (wires_new.length) {
        network_new[id] = wires_new.reduce(
          (acc, wire) => ({ ...acc, [wire.id]: wire }),
          {},
        );
      }
    }

    for (const wire of wires) {
      if (!wire.___touched) {
        this.deleteWire(wire.id, true);
      }
    }

    this._network = network_new;

    this._highlightOccupiedCells();
  }

  private _highlightOccupiedCells() {
    const wires = this._getAllWires();
    const cells = getUniqueWireCells(wires);

    const allPoints = this._hpoints.flatMap((l) => l.flatMap((p) => p));

    for (const point of allPoints) {
      point.opacity(0);
    }

    for (const cell of cells) {
      this._hpoints[cell.idx.x][cell.idx.y].opacity(1);
    }
  }

  /**
   * Place the wire on its final position if possible
   * and reset it
   *
   * If the wire does not have its final position
   * (i.e. user tries to mount on the starting cell),
   * just dispose it
   *
   * @private
   */
  private _finishWire() {
    if (!this._wire) {
      return;
    }

    const cell_to = this._cell_dst_tmp;

    // mount to junction
    if (cell_to) {
      const conn_src = this._wire.conn.src;

      const wires = this._getAllWires();

      const conn = { src: conn_src, dst: cell_to };

      const has_loops = findLoops([...wires.map((w) => w.conn), conn]);

      if (has_loops) {
        console.warn(
          `Loops prohibited (tried to connect ${connToString(conn)})`,
        );
        this._wire.dispose();
        return;
      }

      this._wire.mount({ src: this._wire.conn.src, dst: cell_to });

      this._addWireToNetwork(this._wire);
    } else {
      this._wire.dispose();
    }

    this._resetWire();
  }

  private _getAllWires() {
    return Object.values(this._network).flatMap((net) => Object.values(net));
  }
}
