import type SVG from 'svg.js';
import {
  type Grid,
} from '../../../core/Grid';

import { Layer, type LayerOptions } from '../../../core/Layer';
import { VoltagePopup } from '../../popups/VoltagePopup';
import { type VoltageTable } from '../../../core/Layout';

/**
 * Highlights rectangular cell regions to point out user failures
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class VoltageLayer extends Layer {
  /** CSS class of the layer */
  static get Class() {
    return 'bb-layer-voltage';
  }

  /** layer's main SVG container */
  protected _container: SVG.Container;

  /** SVG group for hover zones */
  private _hovergroup: any;

  /**
   * @inheritdoc
   */
  constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.addClass(VoltageLayer.Class);

    this._hovergroup = undefined;
  }

  /**
   * @inheritdoc
   */
  public __compose__() {
    this._hovergroup = this._container.group().id('hovergroup');

    this._createPopups();
  }

  public setLineVoltages(voltages: VoltageTable) {
    for (const popup of Object.values(this._popups)) {
      popup.updateContent({ voltage: null });
    }
    for (const [line_id, voltage] of Object.entries(voltages)) {
      this._popups[line_id].updateContent({ voltage });
    }
  }

  public getPopup(line_id: string) {
    return this._popups[line_id];
  }

  private _createPopups() {
    for (const [l_id, line] of Object.entries(this.__grid.lines)) {
      this._popups[l_id] = this._createPopup(l_id);
    }
  }

  private _createPopup(line_id: string): VoltagePopup {
    const popup = new VoltagePopup(line_id);
    this._requestPopupDraw(popup, { voltage: null });
    // this._requestPopupDraw(popup, { voltage: 0.5 });

    return popup;
  }
}
