import { Popup } from '../../../core/Popup';
import { invertHexRgb } from '../../../helpers/common';
import { optimizeUnit } from '../../../helpers/units';
import { type VoltagePopupContent } from './types';

/**
 * Popup that displays when the cursor hovers a cell point on {@link VoltageLayer}
 */
export class VoltagePopup extends Popup<VoltagePopupContent> {
  /**
   * Updates the content of current popup
   *
   * @param content details for the popup
   */
  public updateContent(content: VoltagePopupContent): void {
    if (content.voltage === null) {
      this._container.style.visibility = 'hidden';
    } else {
      this._container.style.visibility = 'visible';
    }

    const rgb = '#777';

    this._container.style.backgroundColor = rgb;
    this._container.style.color = invertHexRgb(rgb, true);
    this._container.innerHTML = `<b>Voltage</b>: ${optimizeUnit(
      content.voltage || 0,
      'V',
    )}`;
  }

  protected __draw__(content: VoltagePopupContent): void {
    this.updateContent(content);
  }
}
